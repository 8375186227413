import React, {useContext, useMemo, useCallback} from 'react';
import './style.scss';
import {Table, Button} from 'antd';
import {Context} from '../../store';
import {Link} from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import { HistoryOutlined, PlusOutlined, StarFilled, FireFilled, CalculatorOutlined } from '@ant-design/icons';
import {ReactComponent as TwLogo} from '../../assets/icons/tw-logo2.svg';
import { getUSDollar } from '../../libs/utility';

const SpotPositionTable = ({balance, prevBalance, toggleModal, toggleHistoryModal, toggleCalculatorModal}) => {
    const {state} = useContext(Context);
    const columns = [
        {
            title: 'Add',
            dataIndex: 'add',
            key: 'add',
            className: "action",
            width: 70,
            fixed: 'left',
            render: (add, record) => addButtonBuilder(record),
        },
        {
          title: 'Symbol',
          dataIndex: 'symbol',
          key: 'symbol',
          fixed: 'left',
          render: (symbol, record) => symbolBuilder(symbol, record),
          sorter: (a, b) => ('' + a.symbol.baseAsset).localeCompare(b.symbol.baseAsset),
        },
        {
            title: 'Current Price',
            dataIndex: 'currentPrice',
            key: 'currentPrice',
            render: (currentPrice, record) =>  priceBuilder(currentPrice, record),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity, record) => `${quantity}`,
            sorter: (a, b) => a.quantity - b.quantity,
        },
        {
            title: 'Average Price',
            dataIndex: 'averageCost',
            key: 'averageCost',
            render: (averageCost, record) => `${getUSDollar(averageCost, 6)}`,
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance, record) => `${getUSDollar(balance)}`,
            sorter: (a, b) => a.balance - b.balance,
        },
        {
            title: 'Total PnL',
            dataIndex: 'totalPnL',
            key: 'totalPnL',
            render: (totalPnl, record) => `${getUSDollar(totalPnl)}`,
            sorter: (a, b) => a.totalPnL - b.totalPnL,

        },
        {
            title: 'Current PnL',
            dataIndex: 'currentPnL',
            key: 'currentPnL',
            render: (currentPnL, record) => currentPnlBuilder(currentPnL, record),
            sorter: (a, b) => a.currentPnL - b.currentPnL,
        },
        {
            title: 'Pnl (%)',
            dataIndex: 'percentPnL',
            key: 'percentPnL',
            render: (percentPnL, record) => percentPnlBuilder(percentPnL, record),
            sorter: (a, b) => a.percentPnL - b.percentPnL,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            className: "action",
            width: 120,
            render: (action, record) => actionButtonBuilder(record),
        }
    ];

    const symbolBuilder = (symbol, record) => {
        //console.log('gelen symbol', symbol);
        return <div className="symbol">
            {<Link to={`/spot-currencies/${symbol.baseAsset.toLowerCase()}`}>
            <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${symbol.iconUrl}`} alt="" />
                <span><strong>{symbol.baseAsset}</strong></span>             
            </Link>}
            <div className="pos-period">
                {record.period === "mid" && <StarFilled className='mid' />}
                {record.period === "short" && <FireFilled className='short' />}
            </div>
        </div>
    }

    const priceBuilder = useCallback((price, record) => {
        let oldPrice = 0;
        if(prevBalance && prevBalance.length) {
            let getWalletItem = prevBalance?.find(p => p.id === record.id) || 0;
            oldPrice = getWalletItem.currentPrice;
        }

        return <span className={price >= oldPrice ? "green" : "red"}>{getUSDollar(price, 5)}</span>
    }, [balance]); 

    const currentPnlBuilder = (currentPnL, record) => {
        //let currentPnl = (record.currentPrice * record.quantity) - (record.quantity * record.averageCost);
        return <span className={currentPnL >= 0 ? "green" : "red"}>{getUSDollar(currentPnL)}</span>
    }

    const percentPnlBuilder = (percentPnL, record) => {
        //let percentPnl = record.averageCost > 0 ? ((record.currentPrice - record.averageCost) * 100) / record.averageCost : 0;
        return <span className={percentPnL >= 0 ? "green" : "red"}>{Number(percentPnL).toFixed(2)} %</span>
    }

    const addNewProcess = (symbolId, period) => {
        toggleModal(symbolId, period);
    }

    const openProcessHistory = (symbolId, period) => {
        toggleHistoryModal(symbolId, period);
    }

    const openCalculatorModal = (symbolId, period) => {
        toggleCalculatorModal(symbolId, period);
    }

    const openUrl = (symbol) => {
        window.open(`https://www.tradingview.com/chart/fu2csY4t/?symbol=${symbol.market.toUpperCase()}%3A${symbol.symbol}`, '_blank');
    }

    const addButtonBuilder = (record) => {
        return (
            <div className="table-action">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} size={"middle"} className='btn-add-process btn-cryptoverse' onClick={() => addNewProcess(record.symbolId, record.period)} />
            </div>
        )
    }

    const actionButtonBuilder = (record) => {
        return (
            <div className="table-action">
            <Button type="primary" shape="circle" className='btn-gray' icon={<HistoryOutlined />} size={"middle"} onClick={() => openProcessHistory(record.symbolId, record.period)} />
            <Button type="primary" shape="circle" className='btn-cryptoverse' icon={<CalculatorOutlined />} size={"middle"} onClick={() => openCalculatorModal(record.symbolId, record.period)} />
            <Button type="primary" shape="circle" className='btn-black' icon={<span role="img" aria-label="tw" className="anticon anticon-history"><TwLogo /></span>} size={"middle"} onClick={() => openUrl(record.symbol)} />
            </div>
        )
    }

    return (
        <div className='current-wallet-table'>
            <Table columns={columns} dataSource={balance} bordered pagination={false} />
        </div>
    );
};


export default React.memo(SpotPositionTable);
