import React, { useContext } from 'react';
import {Routes, Route, Redirect, Navigate} from 'react-router-dom';
import Home from '../pages/Home';
import Calculator from '../pages/Calculator';
import Analysis from '../pages/Analysis';
import Currencies from '../pages/Currencies';
import FuturesDetails from '../pages/Currencies/FuturesDetails';
import SpotDetails from '../pages/Currencies/SpotDetails';
import Details from '../pages/Currencies/Details';
import ProfitLossStats from '../pages/ProfitLossStats';
import Simulator from '../pages/Simulator';
import AI from '../pages/AI';
import SpotBalance from '../pages/SpotBalance';
import Wallet from '../pages/Wallet';
import Cumulative from '../pages/Cumulative';
import Accounts from '../pages/Accounts';
import {allAccess} from '../libs/enums';
import { AuthContext } from '../contexts/AuthContext';

const Router = () => {
    const { user } = useContext(AuthContext);

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/analysis" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Analysis />} />
            <Route path="/currencies" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Currencies />} />
            <Route path="/currencies/details/:id" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Details />} />
            <Route path="/futures-currencies/:id" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <FuturesDetails />} />
            <Route path="/spot-currencies/:id" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <SpotDetails />} />
            <Route path="/calculator" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Calculator />} />
            <Route path="/all-pnl" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <ProfitLossStats />} />
            <Route path="/simulator" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Simulator />} />
            <Route path="/spot-balance" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <SpotBalance />} />
            <Route path="/wallet" element={<Wallet />} />
            <Route path="/cumulative" element={!allAccess.includes(user.uid) ? <Navigate to="/" replace /> : <Cumulative />} />
            <Route path="/accounts" element={<Accounts />} />


        </Routes>
    );
};

export default Router;