import { createContext, useReducer } from "react";
import reducer from "./reducer";
import FirebaseSymbols from '../data/firebase-symbols.json';
import FirebaseSymbolsTest from '../data/firebase-symbols-test.json';

const simulatorConfig = {
    balance: 0,
    activeBalance: 0,
    leverage: 1,
    fixedEntryPrice: 0,
}

const analysisTypes = {
    upBottom: true,
    downBottom: true,
    upMid: true,
    downMid: true,
    upOverflow: true,
    upOverflowPlus: true,
    downOverflow: true,
    downOverflowPlus: true,
    fullOverflow: true,
    upTop: true,
    downTop: true,
}

const processDirection = {
    long: true,
    short: false
}

const analysisOptions = {
    timePeriod: '4h',
    pivotPeriod: 6,
    backBar: 500,
    model: 'SD',
    analysisTypes: analysisTypes,
    noClosing: false,
    stopLoss: 5,
    takeProfit: 0,
    entryStop: 0,
    trailingActivation: 2,
    trailingCallback: 2,
    posTimeLimit: 0,
    entryAmount: 0,
    localData: false,
    loginFree: false,
    pivotSource: 'high-low',
    processDirection: processDirection,
    useCurrentTimezoneSupDem: false
}

const initialState = {
    symbols: FirebaseSymbols.symbols.filter(s => s.market === "Binance" && s.state !== "inactive" && (s.type === "FUTURES" || s.type === "BOTH")),
    firebaseSymbols: FirebaseSymbols.symbols,
    marketType: "FUTURES",
    startListenSocket: false,
    kLinesList: {},
    supdemData: {},
    supports: {},
    oldPositions: [],
    openPositions: [],
    currentDeals: [],
    currentEma20Deals: [],
    currentRsiUp30Deals: [],
    currentRsiDown30Deals: [],
    currentEma100Deals: [],
    currentEma200Deals: [],
    currentSrDeals: [],
    currentUpwardExpansions: [],
    currentTrendBreaks: [],
    showCalculatorModal: false,
    showAnalysisModal: false,
    analysisModel: 'percent3',
    enabledSimulator: false,
    simulatorConfig: simulatorConfig,
    analysisOptions: analysisOptions,
    showDealScreen: false
};

/*
const editedSymbols = Object.keys(Symbols.symbols).map(symbol => {
    console.log(Symbols.symbols[symbol]);
    Symbols.symbols[symbol].positionModel = "dema34 - dema89";
    Symbols.symbols[symbol].maturity = "L";
    Symbols.symbols[symbol].riskLevel = "green";

    return Symbols.symbols[symbol];
});

console.log(editedSymbols);
*/
export const Context = createContext(initialState);

export const Provider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Context.Provider value={{state, dispatch}}>
            {children}
        </Context.Provider>
    );
}

