export const basePoint = 50;

export const cocTotal = 46;//Object.values(coc).reduce((a, b) => a + b);

//export const fibonacci = [5, 8, 9, 12, 13, 21, 26, 34, 55, 89, 144, 233, 377];
export const fibonacci = [20, 21, 50, 100, 200];

// Coefficient of Comparison
export const coc = {
    ma5: 1,
    ma8: 2,
    ma13: 3,
    ma21: 3,
    ma34: 5,
    ma55: 5,
    ma89: 3,
    ma144: 2,
    ma233: 1,
    ma377: 1,
    ema5: 1,
    ema8: 2,
    ema13: 3,
    ema21: 3,
    ema34: 5,
    ema55: 5,
    ema89: 3,
    ema144: 2,
    ema233: 1,
    ema377: 1,
    dema5: 1,
    dema8: 1,
    dema13: 2,
    dema21: 2,
    dema34: 3,
    dema55: 3,
    dema89: 5,
    dema144: 5,
    dema233: 1,
    dema377: 1
}

// Filter Position Types
export const positionTypes = [
    {
        name: "ma5-8",
        condition1: "ma5",
        condition2: "ma8"
    },
    {
        name: "ma5-13",
        condition1: "ma5",
        condition2: "ma13"
    },
    {
        name: "ma5-21",
        condition1: "ma5",
        condition2: "ma21"
    },
    {
        name: "ma5-34",
        condition1: "ma5",
        condition2: "ma34"
    },
    {
        name: "ma5-55",
        condition1: "ma5",
        condition2: "ma55"
    },
    {
        name: "ma5-89",
        condition1: "ma5",
        condition2: "ma89"
    },
    {
        name: "ma8-13",
        condition1: "ma8",
        condition2: "ma13"
    },
    {
        name: "ma8-21",
        condition1: "ma8",
        condition2: "ma21"
    },
    {
        name: "ma8-34",
        condition1: "ma8",
        condition2: "ma34"
    },
    {
        name: "ma8-55",
        condition1: "ma8",
        condition2: "ma55"
    },
    {
        name: "ma8-89",
        condition1: "ma8",
        condition2: "ma89"
    },
    {
        name: "ma13-21",
        condition1: "ma13",
        condition2: "ma21"
    },
    {
        name: "ma13-34",
        condition1: "ma13",
        condition2: "ma34"
    },
    {
        name: "ma13-55",
        condition1: "ma13",
        condition2: "ma55"
    },
    {
        name: "ma13-89",
        condition1: "ma13",
        condition2: "ma89"
    },
    {
        name: "ma21-34",
        condition1: "ma21",
        condition2: "ma34"
    },
    {
        name: "ma21-55",
        condition1: "ma21",
        condition2: "ma55"
    },
    {
        name: "ma21-89",
        condition1: "ma21",
        condition2: "ma89"
    },
    {
        name: "ma34-55",
        condition1: "ma34",
        condition2: "ma55"
    },   
    {
        name: "ma34-89",
        condition1: "ma34",
        condition2: "ma89"
    },
    {
        name: "ma55-89",
        condition1: "ma55",
        condition2: "ma89"
    },
    {
        name: "ema5-8",
        condition1: "ema5",
        condition2: "ema8"
    },
    {
        name: "ema5-13",
        condition1: "ema5",
        condition2: "ema13"
    },
    {
        name: "ema5-21",
        condition1: "ema5",
        condition2: "ema21"
    },
    {
        name: "ema5-34",
        condition1: "ema5",
        condition2: "ema34"
    },
    {
        name: "ema5-55",
        condition1: "ema5",
        condition2: "ema55"
    },
    {
        name: "ema5-89",
        condition1: "ema5",
        condition2: "ema89"
    },
    {
        name: "ema8-13",
        condition1: "ema8",
        condition2: "ema13"
    },
    {
        name: "ema8-21",
        condition1: "ema8",
        condition2: "ema21"
    },
    {
        name: "ema8-34",
        condition1: "ema8",
        condition2: "ema34"
    },
    {
        name: "ema8-55",
        condition1: "ema8",
        condition2: "ema55"
    },
    {
        name: "ema8-89",
        condition1: "ema8",
        condition2: "ema89"
    },
    {
        name: "ema13-21",
        condition1: "ema13",
        condition2: "ema21"
    },
    {
        name: "ema13-34",
        condition1: "ema13",
        condition2: "ema34"
    },
    {
        name: "ema13-55",
        condition1: "ema13",
        condition2: "ema55"
    },
    {
        name: "ema13-89",
        condition1: "ema13",
        condition2: "ema89"
    },
    {
        name: "ema21-34",
        condition1: "ema21",
        condition2: "ema34"
    },
    {
        name: "ema21-55",
        condition1: "ema21",
        condition2: "ema55"
    },
    {
        name: "ema21-89",
        condition1: "ema21",
        condition2: "ema89"
    },
    {
        name: "ema34-55",
        condition1: "ema34",
        condition2: "ema55"
    },   
    {
        name: "ema34-89",
        condition1: "ema34",
        condition2: "ema89"
    },
    {
        name: "ema55-89",
        condition1: "ema55",
        condition2: "ema89"
    },    
    {
        name: "dema5-13",
        condition1: "dema5",
        condition2: "dema13"
    },
    {
        name: "dema5-21",
        condition1: "dema5",
        condition2: "dema21"
    },
    {
        name: "dema5-34",
        condition1: "dema5",
        condition2: "dema34"
    },
    {
        name: "dema5-55",
        condition1: "dema5",
        condition2: "dema55"
    },
    {
        name: "dema5-89",
        condition1: "dema5",
        condition2: "dema89"
    },  
    {
        name: "dema8-13",
        condition1: "dema8",
        condition2: "dema13"
    },
    {
        name: "dema8-21",
        condition1: "dema8",
        condition2: "dema21"
    },
    {
        name: "dema8-34",
        condition1: "dema8",
        condition2: "dema34"
    },
    {
        name: "dema8-55",
        condition1: "dema8",
        condition2: "dema55"
    },
    {
        name: "dema8-89",
        condition1: "dema8",
        condition2: "dema89"
    },
    {
        name: "dema13-21",
        condition1: "dema13",
        condition2: "dema21"
    },
    {
        name: "dema13-34",
        condition1: "dema13",
        condition2: "dema34"
    },
    {
        name: "dema13-55",
        condition1: "dema13",
        condition2: "dema55"
    },
    {
        name: "dema13-89",
        condition1: "dema13",
        condition2: "dema89"
    },
    {
        name: "dema21-34",
        condition1: "dema21",
        condition2: "dema34"
    },
    {
        name: "dema21-55",
        condition1: "dema21",
        condition2: "dema55"
    },
    {
        name: "dema21-89",
        condition1: "dema21",
        condition2: "dema89"
    },
    {
        name: "dema34-55",
        condition1: "dema34",
        condition2: "dema55"
    },  
    {
        name: "dema34-89",
        condition1: "dema34",
        condition2: "dema89"
    },
    {
        name: "dema55-89",
        condition1: "dema55",
        condition2: "dema89"
    },
    {
        name: "Macd: 5-21-5",
        condition1: "ema5-21",
        condition2: "ema5-21-ema5"
    },
    {
        name: "Macd: 5-21-8",
        condition1: "ema5-21",
        condition2: "ema5-21-ema8"
    },
    {
        name: "Macd: 5-21-13",
        condition1: "ema5-21",
        condition2: "ema5-21-ema13"
    },
    {
        name: "Macd: 5-34-5",
        condition1: "ema5-34",
        condition2: "ema5-34-ema5"
    },
    {
        name: "Macd: 5-34-8",
        condition1: "ema5-34",
        condition2: "ema5-34-ema8"
    },
    {
        name: "Macd: 5-34-13",
        condition1: "ema5-34",
        condition2: "ema5-34-ema13"
    },
    {
        name: "Macd: 8-21-5",
        condition1: "ema8-21",
        condition2: "ema8-21-ema5"
    },
    {
        name: "Macd: 8-21-8",
        condition1: "ema8-21",
        condition2: "ema8-21-ema8"
    },
    {
        name: "Macd: 8-21-13",
        condition1: "ema8-21",
        condition2: "ema8-21-ema13"
    },
    {
        name: "Macd: 8-34-5",
        condition1: "ema8-34",
        condition2: "ema8-34-ema5"
    },
    {
        name: "Macd: 8-34-8",
        condition1: "ema8-34",
        condition2: "ema8-34-ema8"
    },
    {
        name: "Macd: 8-34-13",
        condition1: "ema8-34",
        condition2: "ema8-34-ema13"
    },
    {
        name: "Macd: 13-21-5",
        condition1: "ema13-21",
        condition2: "ema13-21-ema5"
    },
    {
        name: "Macd: 13-21-8",
        condition1: "ema13-21",
        condition2: "ema13-21-ema8"
    },
    {
        name: "Macd: 13-21-13",
        condition1: "ema13-21",
        condition2: "ema13-21-ema13"
    },
    {
        name: "Macd: 13-34-5",
        condition1: "ema13-34",
        condition2: "ema13-34-ema5"
    },
    {
        name: "Macd: 13-34-8",
        condition1: "ema13-34",
        condition2: "ema13-34-ema8"
    },
    {
        name: "Macd: 13-34-13",
        condition1: "ema13-34",
        condition2: "ema13-34-ema13"
    },
    {
        name: "Macd: 12-26-9",
        condition1: "ema12-26",
        condition2: "ema12-26-ema9"
    },
    {
        name: "P-ema13-21-34",
        condition1: "ema13",
        condition2: "ema21",
        condition3: "ema34"
    }, 
    {
        name: "P-ema13-21-55",
        condition1: "ema13",
        condition2: "ema21",
        condition3: "ema55"
    }, 
    {
        name: "P-ema8-13-34",
        condition1: "ema8",
        condition2: "ema13",
        condition3: "ema34"
    }, 
    {
        name: "P-ema8-21-34",
        condition1: "ema8",
        condition2: "ema21",
        condition3: "ema34"
    }, 
    {
        name: "P-ema8-21-55",
        condition1: "ema8",
        condition2: "ema21",
        condition3: "ema55"
    },  
    {
        name: "P-ema5-13-34",
        condition1: "ema5",
        condition2: "ema13",
        condition3: "ema34"
    },  
    {
        name: "P-ema5-21-34",
        condition1: "ema5",
        condition2: "ema21",
        condition3: "ema34"
    },
    {
        name: "P-ema5-13-55",
        condition1: "ema5",
        condition2: "ema13",
        condition3: "ema55"
    },
    {
        name: "P-ema5-21-55",
        condition1: "ema5",
        condition2: "ema21",
        condition3: "ema55"
    }
    /*
    {
        name: "close-ema8",
        condition1: "close",
        condition2: "ema8"
    },
    {
        name: "close-ema13",
        condition1: "close",
        condition2: "ema13"
    },
    {
        name: "close-ema21",
        condition1: "close",
        condition2: "ema21"
    },
    {
        name: "close-ema34",
        condition1: "close",
        condition2: "ema34"
    },
    {
        name: "close-dema8",
        condition1: "close",
        condition2: "dema8"
    },
    {
        name: "close-dema13",
        condition1: "close",
        condition2: "dema13"
    },
    {
        name: "close-dema21",
        condition1: "close",
        condition2: "dema21"
    },
    {
        name: "close-dema34",
        condition1: "close",
        condition2: "dema34"
    },*/
    /*
    {
        name: "CLS-21",
        condition1: "dema21",
        condition2: "dema21"
    },
    {
        name: "CLS-34",
        condition1: "dema34",
        condition2: "dema34"
    },
    {
        name: "CLS-55",
        condition1: "dema55",
        condition2: "dema55"
    },
    {
        name: "CLS-89",
        condition1: "dema89",
        condition2: "dema89"
    },
    {
        name: "CLS-144",
        condition1: "dema144",
        condition2: "dema144"
    },
    {
        name: "CLS-233",
        condition1: "dema233",
        condition2: "dema233"
    },
    {
        name: "CLS-13",
        condition1: "ema13",
        condition2: "ema13"
    },
    {
        name: "CLS-21",
        condition1: "ema21",
        condition2: "ema21"
    },
    {
        name: "CLS-34",
        condition1: "ema34",
        condition2: "ema34"
    },
    {
        name: "CLS-55",
        condition1: "ema55",
        condition2: "ema55"
    }, 
    {
        name: "CLS-89",
        condition1: "ema89",
        condition2: "ema89"
    },   
    {
        name: "CLS-144",
        condition1: "ema144",
        condition2: "ema144"
    },
    {
        name: "CLS-233",
        condition1: "ema233",
        condition2: "ema233"
    }, 
    {
        name: "CLS - ma34",
        condition1: "ma34",
        condition2: "ma34"
    }, 
    {
        name: "CLS - ma89",
        condition1: "ma89",
        condition2: "ma89"
    },
    */
    /*
    {
        name: "CLS-5-13",
        condition1: "dema5",
        condition2: "dema13"
    },
    {
        name: "CLS-8-13",
        condition1: "dema8",
        condition2: "dema13"
    },
    {
        name: "CLS-5-21",
        condition1: "dema5",
        condition2: "dema21"
    },
    {
        name: "CLS-8-21",
        condition1: "dema8",
        condition2: "dema21"
    },
    {
        name: "CLS-13-21",
        condition1: "dema13",
        condition2: "dema21"
    },
    {
        name: "CLS-5-34",
        condition1: "dema5",
        condition2: "dema34"
    },
    {
        name: "CLS-8-34",
        condition1: "dema8",
        condition2: "dema34"
    },
    {
        name: "CLS-13-34",
        condition1: "dema13",
        condition2: "dema34"
    },
    {
        name: "CLS-21-34",
        condition1: "dema21",
        condition2: "dema34"
    },
    {
        name: "CLS-5-55",
        condition1: "dema5",
        condition2: "dema55"
    },
    {
        name: "CLS-8-55",
        condition1: "dema8",
        condition2: "dema55"
    },
    {
        name: "CLS-13-55",
        condition1: "dema13",
        condition2: "dema55"
    },
    {
        name: "CLS-21-55",
        condition1: "dema21",
        condition2: "dema55"
    },
    {
        name: "CLS-34-55",
        condition1: "dema34",
        condition2: "dema55"
    },
    {
        name: "CLS-5-89",
        condition1: "dema5",
        condition2: "dema89"
    },    
    {
        name: "CLS-8-89",
        condition1: "dema8",
        condition2: "dema89"
    },
    {
        name: "CLS-13-89",
        condition1: "dema13",
        condition2: "dema89"
    },
    {
        name: "CLS-21-89",
        condition1: "dema21",
        condition2: "dema89"
    },
    {
        name: "CLS-34-89",
        condition1: "dema34",
        condition2: "dema89"
    },
    {
        name: "CLS-55-89",
        condition1: "dema55",
        condition2: "dema89"
    },
    {
        name: "CLS-5-13",
        condition1: "ema5",
        condition2: "ema13"
    },
    {
        name: "CLS-8-13",
        condition1: "ema8",
        condition2: "ema13"
    },
    {
        name: "CLS-5-21",
        condition1: "ema5",
        condition2: "ema21"
    },
    {
        name: "CLS-8-21",
        condition1: "ema8",
        condition2: "ema21"
    },
    {
        name: "CLS-13-21",
        condition1: "ema13",
        condition2: "ema21"
    },
    {
        name: "CLS-5-34",
        condition1: "ema5",
        condition2: "ema34"
    },
    {
        name: "CLS-8-34",
        condition1: "ema8",
        condition2: "ema34"
    },
    {
        name: "CLS-13-34",
        condition1: "ema13",
        condition2: "ema34"
    },
    {
        name: "CLS-21-34",
        condition1: "ema21",
        condition2: "ema34"
    },
    {
        name: "CLS-5-55",
        condition1: "ema5",
        condition2: "ema55"
    },
    {
        name: "CLS-8-55",
        condition1: "ema8",
        condition2: "ema55"
    },
    {
        name: "CLS-13-55",
        condition1: "ema13",
        condition2: "ema55"
    },
    {
        name: "CLS-21-55",
        condition1: "ema21",
        condition2: "ema55"
    },
    {
        name: "CLS-34-55",
        condition1: "ema34",
        condition2: "ema55"
    },
    {
        name: "CLS-5-89",
        condition1: "ema5",
        condition2: "ema89"
    },    
    {
        name: "CLS-8-89",
        condition1: "ema8",
        condition2: "ema89"
    },
    {
        name: "CLS-13-89",
        condition1: "ema13",
        condition2: "ema89"
    },
    {
        name: "CLS-21-89",
        condition1: "ema21",
        condition2: "ema89"
    },
    {
        name: "CLS-34-89",
        condition1: "ema34",
        condition2: "ema89"
    },
    {
        name: "CLS-55-89",
        condition1: "ema55",
        condition2: "ema89"
    },    
    {
        name: "CLS - ma34 - ma89",
        condition1: "ma34",
        condition2: "ma89"
    },     
    */
    /*
    {
        name: "close - ma8",
        condition1: "close",
        condition2: "ma8"
    },
    {
        name: "close - ma13",
        condition1: "close",
        condition2: "ma13"
    },
    {
        name: "close - ma21",
        condition1: "close",
        condition2: "ma21"
    },
    {
        name: "close - ma34",
        condition1: "close",
        condition2: "ma34"
    },
    {
        name: "close - ma55",
        condition1: "close",
        condition2: "ma55"
    },
    {
        name: "close - ma89",
        condition1: "close",
        condition2: "ma89"
    },
    {
        name: "close - ma144",
        condition1: "close",
        condition2: "ma144"
    },
    {
        name: "close-8",
        condition1: "close",
        condition2: "ema8"
    },
    {
        name: "close-13",
        condition1: "close",
        condition2: "ema13"
    },
    {
        name: "close-21",
        condition1: "close",
        condition2: "ema21"
    },
    {
        name: "close-34",
        condition1: "close",
        condition2: "ema34"
    },
    {
        name: "close-55",
        condition1: "close",
        condition2: "ema55"
    },
    {
        name: "close-89",
        condition1: "close",
        condition2: "ema89"
    },
    {
        name: "close-144",
        condition1: "close",
        condition2: "ema144"
    },
    {
        name: "close-8",
        condition1: "close",
        condition2: "dema8"
    },
    {
        name: "close-13",
        condition1: "close",
        condition2: "dema13"
    },
    {
        name: "close-21",
        condition1: "close",
        condition2: "dema21"
    },
    {
        name: "close-34",
        condition1: "close",
        condition2: "dema34"
    },
    {
        name: "close-55",
        condition1: "close",
        condition2: "dema55"
    },
    {
        name: "close-89",
        condition1: "close",
        condition2: "dema89"
    },
    {
        name: "close-144",
        condition1: "close",
        condition2: "dema144"
    }
    */
]

export const analysisModel = [
    {
        label: 'Explosion',
        value: 'explosion',
    },
    {
        label: 'Explosion Rsi 50-65',
        value: 'explosion5065',
    },
    {
        label: 'Explosion Rsi 45-60',
        value: 'explosion4560',
    },
    {
        label: 'Explosion (Loose)',
        value: 'explosionLoose',
    },
    {
        label: 'Explosion (Tight)',
        value: 'explosionTight',
    },
    {
        label: 'Percent 3',
        value: 'percent3',
    },/*
    {
        label: 'Explosion + Percent 3',
        value: 'explosionPercent3',
    },*/
    {
        label: 'Boll + Rsi',
        value: 'bollRsi',
    },
    {
        label: 'Boll Bandwidth',
        value: 'bollBandwidth',
    },/*
    {
        label: 'Explosion Bundle',
        value: 'explosionBundle',
    },
    {
        label: 'Karma Bundle',
        value: 'karmaBundle',
    },*/
    {
        label: 'Zombie Spot',
        value: 'zombieSpot',
    },
    {
        label: 'Depth Hill',
        value: 'depthHill'
    },/*
    {
        label: 'Rsi Divergence',
        value: 'rsiDivergence'
    },*/
    {
        label: 'Artificial Intelligence',
        value: 'AI'
    },
    {
        label: 'Trend Channels',
        value: 'TC'
    },
    {
        label: 'Supply Demand',
        value: 'SD'
    } 
];

export const timePeriods = [
    {
        label: '5m',
        value: '5m',
    },
    {
        label: '15m',
        value: '15m',
    },
    {
        label: '30m',
        value: '30m',
    },
    {
        label: '1h',
        value: '1h',
    },
    {
        label: '2h',
        value: '2h',
    },
    {
        label: '4h',
        value: '4h',
    },
    {
        label: '8h',
        value: '8h',
    },
    {
        label: '1d',
        value: '1d',
    }
];

export const marketType = [
    {
        label: 'Spot',
        value: 'SPOT',
    },
    {
        label: 'Futures',
        value: 'FUTURES',
    }
];

export const accountEnum = {
    SPOT: 'SPOT',
    FUTURES: 'FUTURES',
    CUMULATIVE: 'CUMULATIVE'
}

export const accountType = [
    {
        label: 'Spot',
        value: 'SPOT',
    },
    {
        label: 'Futures',
        value: 'FUTURES',
    },
    {
        label: 'Cumulative',
        value: 'CUMULATIVE',
    }
];

export const analysisTypes = [
    {
        label: 'Up Trend Bottom Touch',
        value: 'upBottom',
    },
    {
        label: 'Down Trend Bottom Touch',
        value: 'downBottom',
    },
    {
        label: 'Up Trend Mid Touch',
        value: 'upMid',
    },
    {
        label: 'Down Trend Mid Touch',
        value: 'downMid',
    },
    {
        label: 'Up Trend Overflow',
        value: 'upOverflow',
    },
    {
        label: 'Up Trend Overflow with Inside Down Trend',
        value: 'upOverflowPlus',
    },
    {
        label: 'Down Trend Overflow',
        value: 'downOverflow',
    },
    {
        label: 'Down Trend Overflow with Inside Up Trend',
        value: 'downOverflowPlus',
    },
    {
        label: 'Down and Up Overflow',
        value: 'fullOverflow',
    },
    {
        label: 'Up Trend Top Touch',
        value: 'upTop',
    },
    {
        label: 'Down Trend Top Touch',
        value: 'downTop',
    }
];

export const processDirection = [
    {
        label: 'LONG',
        value: 'long',
    },
    {
        label: 'SHORT',
        value: 'short',
    }
];

export const cexes = [
    {
        "url": "https://www.okx.com/",
        "name": "Okx",
        "id": "1O75uY05iKabANSbkm6G"
    },
    {
        "url": "https://www.gate.io/",
        "name": "Gate.io",
        "id": "7BlNXjSLuobteevLdeOv"
    },
    {
        "name": "Bitget",
        "url": "https://www.bitget.com/",
        "id": "E2R3om8z0c3bo9I76V7l"
    },
    {
        "url": "https://crypto.com/",
        "name": "Crypto.com",
        "id": "J5yUvmzouqsY2wyakhZP"
    },
    {
        "name": "KuCoin",
        "url": "https://www.kucoin.com/",
        "id": "STFdhw78ZcBd0nimLMil"
    },
    {
        "name": "Coinbase",
        "url": "https://www.coinbase.com/",
        "id": "UcHmwKWOpKrWT7OYvEVY"
    },
    {
        "name": "ByBit",
        "url": "https://www.bybit.com/",
        "id": "Y5uWUgTv5Jqwi59xIj3P"
    },
    {
        "url": "https://www.binance.com/",
        "name": "Binance",
        "id": "b93339kCuXPjdOqJIXq6"
    },
    {
        "url": "https://www.binance.com/",
        "name": "Binance Sub",
        "id": "If12JhhHxGDo8pl4fFSN"
    },
    {
        "url": "https://www.trbinance.com/",
        "name": "Binance TR",
        "id": "dOQIynjh3GbWMsWrcKBJ"
    },    
    {
        "name": "Htx",
        "url": "https://www.htx.com/",
        "id": "ejTRkW264qcwz479jWAT"
    },
    {
        "name": "Bitfinex",
        "url": "https://www.bitfinex.com/",
        "id": "iCWZYQB9ssfEbz5hIYZv"
    },
    {
        "name": "Bitstamp",
        "url": "https://www.bitstamp.net/",
        "id": "llUZtCg3tM59iGQ3j7gk"
    },
    {
        "url": "https://bingx.com/",
        "name": "BingX",
        "id": "ukUa7CnR8mkcXy7dHYIW"
    },
    {
        "name": "Mexc",
        "url": "https://www.mexc.com/",
        "id": "uypVHAvgeWWqUDvUu3Ai"
    },
    {
        "url": "https://www.kraken.com/",
        "name": "Kraken",
        "id": "vZpCHlX35gyOc6xnDPOb"
    }
];

export const importCexOrder = ["Binance", "Binance Sub", "Binance TR", "Gate.io", "Okx", "Mexc", "Coinbase", "KuCoin", "Kraken", "BingX", "Bitstamp", "Bitfinex", "Htx", "ByBit", "Crypto.com", "Bitget"];

export const importCexTypeOrder = ["SPOT", "CUMULATIVE", "FUTURES"];

const analysisTypesh = {
    upBottom: true,
    downBottom: true,
    upMid: true,
    downMid: true,
    upOverflow: true,
    upOverflowPlus: true,
    downOverflow: true,
    downOverflowPlus: true,
    fullOverflow: true,
    upTop: true,
    downTop: true,
}

export const allAccess = ['2N94LxkquqZWiRawQiJIYNaVhi32', 'xcyX9pfM6FMX9wbUOgFzCojFNpA3', 'oC2P1FiB3CZ7XLIeu6FLRSX2kRL2'];