import React, { useEffect, useContext } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { Context } from '../../store';
import { CalculatorOutlined } from '@ant-design/icons';
import './style.scss';
import {analysisModel, allAccess } from '../../libs/enums';
import {Button} from 'antd';
import { Drawer} from 'antd';
import { AuthContext } from '../../contexts/AuthContext';

const nav = [
    {
        name: "Home",
        path: '/',
        access: 'general'
    },
    {
        name: "Analysis",
        path: '/analysis',
        access: 'required'
    },
    {
        name: "Currencies",
        path: "/currencies",
        access: 'required'
    },
    {
        name: "All PNL",
        path: "/all-pnl",
        access: 'required',
    },
    {
        name: "Spot Wallets",
        path: "/wallet",
        access: 'general'
    },/*
    {
        name: "Cumulative",
        path: "/cumulative"
    },*/
    {
        name: "Accounts",
        path: "/accounts",
        access: 'general'
    },    
];

const MobileDrawer = ({onClose, open}) => {
    const location = useLocation();
    const {state, dispatch} = useContext(Context);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const navigation = nav.map((navItem, i) => {
        let className = location.pathname === navItem.path ? "active": "";
        if (navItem.access === "required" && !allAccess.includes(user.uid)) {
            return null;
        }
        return <li key={i} className={className}>
        <Link to={navItem.path} onClick={onClose}>{navItem.name}</Link>
      </li>
    });

    return (
        <Drawer title="Mobile Navigation" placement="left" onClose={onClose} open={open} getContainer={false} width={'100%'}>
            <ul>
                {navigation}        
            </ul>
        </Drawer>
    );
};

export default MobileDrawer;