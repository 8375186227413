import React, {useState, useContext, useEffect, useRef} from 'react';
import {Modal, Table, InputNumber, Button, Row, Col, Radio, Select, message, Form} from 'antd';
import { Context } from '../../store';
import { cryptoverseService } from '../../services';
import './style.scss';
import { firebaseService } from '../../services';
import { AuthContext } from '../../contexts/AuthContext';
import { getUSDollar } from '../../libs/utility';

const processSide = [
    {
        label: 'Buy',
        value: 'Buy',
    },
    {
        label: 'Sell',
        value: 'Sell',
    }
];

const periods = [
    {
        label: 'Long',
        value: 'long',
    },
    {
        label: 'Mid',
        value: 'mid',
    },
    {
        label: 'Short',
        value: 'short',
    }
];

const SpotCalculatorModal = (props) => {
    console.log('SpotCalculatorModal', props);
    const [form] = Form.useForm();
    const { user } = useContext(AuthContext);
    //console.log('gelen selected', props.selectedSymbol);
    const {state, dispatch} = useContext(Context);
    const [side, setSide] = useState("");
    const [selectedSymbol, setSelectedSymbol] = useState(null);
    const [cost, setCost] = useState(0);
    const [unitPrice, setUnitPrice] = useState(0);
    const [currentState, setCurrentState] = useState({});
    const [period, setPeriod] = useState('long');
    const [balance, setBalance] = useState({});

    const handleChange = (value) => {
        console.log(`selected ${value}`);
        setSelectedSymbol(value);
    };

    useEffect(() => {
        firebaseService.getBalance(user.uid, props.selectedSymbol.symbol.id, props.period)
        .then(res => {
            //console.log('historical balance', res[0]);
            setBalance(res[0]);
        })
    }, []);

    useEffect(() => {
        if (cost && unitPrice) {
            let addedQuantity = cost / unitPrice;
            let oldPaid = props.selectedSymbol?.quantity * props.selectedSymbol?.averageCost;

            let totalQuantity = props.selectedSymbol?.quantity + addedQuantity;
            let totalPaid = oldPaid + cost;
            let newAverageCost = totalPaid / totalQuantity;
            let newPercentPnL = ((props.selectedSymbol?.currentPrice - newAverageCost) * 100) / newAverageCost;

            let currentState = {
                addedQuantity: addedQuantity,
                newQuantity: totalQuantity,
                newPaid: totalPaid,
                newAverageCost: newAverageCost,
                newPercentPnL: newPercentPnL
            }

            setCurrentState(currentState);
            /*
                // kayıt varsa güncellenecek. (adet ve ort alım fiyatı hesaplanacak.)
                let oldPaid = getBalanceRecord[0].quantity * getBalanceRecord[0].averageCost;
                let totalQnty = Number((newProcess.quantity + getBalanceRecord[0].quantity).toFixed(4));
                let totalPaid = oldPaid + newProcess.total;
                let newAverageCost = totalPaid / totalQnty;
                getBalanceRecord[0].quantity = totalQnty;
                getBalanceRecord[0].averageCost = newAverageCost;

                e.percentPnL = e.averageCost > 0 ? ((r.price - e.averageCost) * 100) / e.averageCost : 0;
            */
        }
    }, [cost, unitPrice]);

    return (
        <>
            <Modal title="Process Calculator" open={props.showModal} footer={null} onCancel={() => props.toggleModal(null)} width={300} height={800}>
                <div className="add-new-process-modal">
                    <Form form={form} layout="vertical" onFinish={() => {}}>
                        <div className='history-info'>
                            <Row gutter={0}>
                                <Col span={24} className="title">
                                    <div className='history-image'>
                                        <img src={`${process.env.PUBLIC_URL}/images/crypto-logo/${props.selectedSymbol?.symbol.iconUrl}`} alt="" />
                                        <p><strong>{props.selectedSymbol?.symbol.symbol}</strong></p> 
                                    </div>   
                                </Col>
                                <Col span={24} className="history-prices">
                                    <p>Current Price: <span>{getUSDollar(props.selectedSymbol?.currentPrice, 6)}</span></p>

                                    <p>Average Cost: <span>{getUSDollar(props.selectedSymbol?.averageCost, 6)}</span></p>
                                    <p>Quantity: <span>{props.selectedSymbol?.quantity}</span></p>
                                    <p>Active Balance: <span>{getUSDollar(props.selectedSymbol?.quantity * props.selectedSymbol?.averageCost)}</span></p>
                                    <p>Percent PnL: <span>{Number(props.selectedSymbol?.percentPnL).toFixed(2)}%</span></p>
                                </Col>
                                {(cost > 0 && unitPrice > 0) &&
                                    <Col span={24}>
                                        <div className='history-prices current'>
                                            <p>Current Avg. Cost: <span>{getUSDollar(currentState.newAverageCost, 6)}</span></p>
                                            <p>Added Qnty: <span>{Number(currentState.addedQuantity).toFixed(5)}</span></p>
                                            <p>Current Qnty: <span>{Number(currentState.newQuantity).toFixed(5)}</span></p>
                                            <p>Current Balance: <span>{getUSDollar(currentState.newPaid)}</span></p>
                                            <p>Current PnL(%): <span>{Number(currentState.newPercentPnL).toFixed(2)}%</span></p>
                                        </div>
                                    </Col>
                                }
                            </Row>  
                        </div>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Enter Cost:" name="cost" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter cost!',
                                    },
                                ]}>
                                    <InputNumber placeholder='Enter cost.' 
                                        value={cost} 
                                        onChange={value => setCost(value)}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                                        />
                                </Form.Item>

                            </Col>
                            <Col span={24}>
                                <Form.Item label="Enter Unit Price:" name="unitPrice" rules={[
                                    {
                                        required: true,
                                        message: 'Please enter unit price!',
                                    },
                                ]}>
                                    <InputNumber placeholder='Enter unit price.'
                                        value={unitPrice} 
                                        onChange={value => setUnitPrice(value)}
                                        formatter={(value) => `$ ${value}`}
                                        parser={(value) => value.replace(/,/g, '.').replace(/\$\s?|()/g, '')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

export default SpotCalculatorModal;